import { action, makeObservable, observable } from "mobx";
import { Toggles } from "../../server/feature-toggles/toggles";
import { defaultToggles } from "../utils/featureTogglesUtils";
class LayoutStore {
  @observable
  public showReCaptchaV3: boolean;
  @observable
  public prerendered: boolean;
  @observable
  public toggles: Toggles;

  constructor() {
    makeObservable(this);
    this.toggleReCaptchaVisibility = this.toggleReCaptchaVisibility.bind(this);
    this.showReCaptchaBadge = this.showReCaptchaBadge.bind(this);
    this.hideReCaptchaBadge = this.hideReCaptchaBadge.bind(this);
    this.showReCaptchaV3 = false;
    this.prerendered = false;
    this.toggles = defaultToggles;
  }

  @action
  public showReCaptchaBadge() {
    this.toggleReCaptchaVisibility(true);
  }

  @action
  public hideReCaptchaBadge() {
    this.toggleReCaptchaVisibility(false);
  }

  @action
  public setLayoutAsPrerendered() {
    this.prerendered = true;
  }

  @action
  public setToggles(newToggles: Toggles): void {
    this.toggles = newToggles;
  }

  private toggleReCaptchaVisibility(recaptchaVisible: boolean): void {
    this.showReCaptchaV3 = recaptchaVisible;
  }
}

export default new LayoutStore();
export { LayoutStore };
