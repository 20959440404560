import React from "react";
import { styles } from "./PaymentLogos.style";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { LazyImage } from "react-lazy-images";

export interface PaymentLogosProps {
  paymentIcons: { name: string }[];
}

const PaymentLogos: React.FC<PaymentLogosProps> = ({ paymentIcons }) => {
  const getIcon = (name: string) => Icons?.[name.replaceAll("-", "")] || "";
  const lazyImagePlaceholder = ({ ref }) => <div ref={ref} />;

  return (
    <div css={styles.root}>
      {paymentIcons &&
        paymentIcons.map(({ name }) => {
          const icon = getIcon(name);
          const Image = ({ imageProps }) => <img css={styles.icon(icon)} draggable="false" {...imageProps} />;
          return (
            name && (
              <div css={styles.logo} key={name}>
                <LazyImage placeholder={lazyImagePlaceholder} actual={Image} />
              </div>
            )
          );
        })}
    </div>
  );
};

export default PaymentLogos;
