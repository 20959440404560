import { ThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import App, { AppContext } from "next/app";
import { Toggles } from "../../server/feature-toggles/toggles";
import Layout from "components/Layout/Layout";
import { OverallProvider } from "contexts";
import MaterialUITheme from "themes/material-ui-theme";
import { googleTagManager } from "domain/SeoAndAnalytics/GoogleTagManager/utils/google-tag-manager";
import { appWithTranslation } from "next-i18next";
import { withRouter } from "next/router";
import { languageConfig } from "i18n/language-config";
import { CacheProvider } from "@emotion/react";
import { cache } from "../emotionCache";
import { hydrate } from "@emotion/css";
import { markerIoManager } from "../domain/MarkerIo/markerIoManager";
import { routesUtil } from "../domain/Routing/utils/routesUtil";
import ContentfulApi from "contentful/contentful-api.class";
import { DefaultSampleReport } from "contentful/PageModularModule";
import { fetchFeatureToggles } from "utils/featureTogglesUtils";
import { Auth0Provider } from "@auth0/auth0-react";
import { EnvironmentVariables } from "utils/config/environment-variables";

export const generateClassName = createGenerateClassName({
  productionPrefix: "css-prod-",
});

class MyApp extends App<{ ids: string[]; toggles: Toggles; defaultSampleReports: DefaultSampleReport }, {}> {
  state = {
    key: 0,
    toggles: null,
  };

  private withMarkerIoWidget: boolean;

  constructor(props) {
    super(props);

    let ids = [];
    if (typeof document != "undefined") {
      const emotionSSR = document.getElementById("emotion-ssr-css");
      ids = emotionSSR.dataset.emotion.split(" ");
    }
    hydrate(ids);
  }

  public componentDidMount() {
    this.setWithMarkerIoWidget();
    if (this.withMarkerIoWidget) {
      markerIoManager.loadMarkerIo();
    }
    void googleTagManager.loadTagManager();
    this.setState({ toggles: this.props.toggles, key: 1 });
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  private setWithMarkerIoWidget(): void {
    const queryMarker = this.props.router.query?.marker as string;
    const routerPath = this.props.router.asPath;
    this.withMarkerIoWidget = MyApp.getMarker(queryMarker, routerPath);
  }

  private static getMarker(queryMarker: string, path: string): boolean {
    const marker = queryMarker || routesUtil.getMarkerPartFromPath(path);
    return marker ? marker.toLowerCase() == "true" : false;
  }

  public render() {
    const { Component, pageProps, toggles, defaultSampleReports } = this.props;
    const pagePropsWithAdditionalProps = { ...pageProps, toggles, defaultSampleReports };
    const auth0Domain = EnvironmentVariables.getAuth0Domain();
    const auth0ClientId = EnvironmentVariables.getAuth0ClientId();

    return (
      <CacheProvider value={cache}>
        <OverallProvider>
          <Auth0Provider domain={auth0Domain} clientId={auth0ClientId}>
            {/* welcome on the frontend -  https://github.com/vercel/next.js/discussions/12693#discussion-2963 */}
            <StylesProvider key={this.state.key} generateClassName={generateClassName}>
              <ThemeProvider theme={MaterialUITheme}>
                <Layout {...pagePropsWithAdditionalProps}>
                  <Component {...pagePropsWithAdditionalProps} />
                </Layout>
              </ThemeProvider>
            </StylesProvider>
          </Auth0Provider>
        </OverallProvider>
      </CacheProvider>
    );
  }
}

MyApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await App.getInitialProps(ctx);
  const toggles = await fetchFeatureToggles();

  const queryLang = ctx.router.query?.lang as string;
  const routerPath = ctx.router.asPath;
  const locale = languageConfig.mapLanguageToLocale(queryLang || routesUtil.getLanguagePartFromPath(routerPath));

  const defaultSampleReports = await ContentfulApi.getInstance().getOptionalAssets(locale);
  return {
    ...initialProps,
    toggles,
    defaultSampleReports: defaultSampleReports,
  };
};
export default withRouter(appWithTranslation(MyApp as any));
