import { NodeStages } from "./stages";
import { EnvironmentVariables } from "./environment-variables";

const isApplicationRunningInNodeModeProduction = () => NodeStages.DEVELOPMENT !== EnvironmentVariables.getNodeEnv();

const isApplicationRunningInNodeModeDevelopment = () => NodeStages.DEVELOPMENT === EnvironmentVariables.getNodeEnv();

export const environment = {
  isApplicationRunningInNodeModeProduction,
  isApplicationRunningInNodeModeDevelopment,
};
