import { css } from "@emotion/react";
import { colors } from "@carfax/web-ui/core";

export const styles = {
  layoutWrapper: (hasGrayBackground: boolean) =>
    css({
      backgroundColor: hasGrayBackground ? colors.backgroundGrey.hex : "initial",
      display: "flex",
      minHeight: "100vh",
      flexDirection: "column",
    }),
  divLayout: () =>
    css({
      flex: "1 1 auto",
    }),
};

export const globalPageStyle = {
  body: (showReCaptchaV3: boolean) =>
    css({
      ".grecaptcha-badge": {
        visibility: showReCaptchaV3 ? "visible" : "hidden",
      },
    }),
};
