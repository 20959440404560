const fontFamilies = {
  Roboto: "Roboto",
};

const fonts = {
  "Roboto Light": {
    // eslint-disable-next-line
    "@font-face": {
      fontFamily: `"${fontFamilies.Roboto}"`,
      src: `local('Roboto Light'),
            local('Roboto-Light'),
            url('/static/fonts/roboto-light.woff2') format('woff2')`,
      fontStyle: "normal",
      fontWeight: 300,
      fontDisplay: "swap",
    },
  },
  "Roboto Regular": {
    // eslint-disable-next-line
    "@font-face": {
      fontFamily: `"${fontFamilies.Roboto}"`,
      src: `local('Roboto'),
            local('Roboto-Regular'),
            url('/static/fonts/roboto-regular.woff2') format('woff2')`,
      fontStyle: "normal",
      fontWeight: 400,
      fontDisplay: "swap",
    },
  },
  "Roboto Medium": {
    "@font-face": {
      fontFamily: `"${fontFamilies.Roboto}"`,
      src: `local('Roboto Medium'),
            local('Roboto-Medium'),
            url('/static/fonts/roboto-medium.woff2') format('woff2')`,
      fontStyle: "normal",
      fontWeight: 500,
      fontDisplay: "swap",
    },
  },
  "Roboto Bold": {
    // eslint-disable-next-line
    "@font-face": {
      fontFamily: `"${fontFamilies.Roboto}"`,
      src: `local('Roboto Bold'),
            local('Roboto-Bold'),
            url('/static/fonts/roboto-bold.woff2') format('woff2')`,
      fontStyle: "normal",
      fontWeight: 700,
      fontDisplay: "swap",
    },
  },
  "Helvetica Neue": {
    "@font-face": {
      fontFamily: "fb-Helvetica",
      src: "local('Helvetica Neue')",
      ascentOverride: "93.8453%",
      descentOverride: "24.6961%",
      sizeAdjust: "98.8578%",
    },
  },
  BlinkMacSystemFont: {
    "@font-face": {
      fontFamily: "fb-MacFont",
      src: "local('BlinkMacSystemFont')",
      ascentOverride: "86.3152%",
      descentOverride: "22.7145%",
      sizeAdjust: "107.4822%",
    },
  },
  Ubuntu: {
    "@font-face": {
      fontFamily: "fb-Ubuntu",
      src: "local('Ubuntu')",
      ascentOverride: "95.105%",
      descentOverride: "25.0276%",
      sizeAdjust: "97.5485%",
    },
  },
};

const fontSizes = {
  heading: {
    H1: {
      mobile: "24px",
      desktop: "42px",
    },
    H2: {
      mobile: "22px",
      desktop: "34px",
    },
    H3: {
      mobile: "20px",
      desktop: "28px",
    },
    H4: {
      mobile: "18px",
      desktop: "24px",
    },
    H5: {
      mobile: "17px",
      desktop: "22px",
    },
    H6: {
      mobile: "17px",
      desktop: "20px",
    },
  },
  body: {
    horse: "16px",
    cat: "14px",
    mouse: "12px",
    butterfly: "10px",
  },
};

const lineHeights = {
  heading: {
    H1: {
      mobile: "32px",
      desktop: "56px",
    },
    H2: {
      mobile: "32px",
      desktop: "44px",
    },
    H3: {
      mobile: "24px",
      desktop: "36px",
    },
    H4: {
      mobile: "24px",
      desktop: "32px",
    },
    H5: {
      mobile: "24px",
      desktop: "32px",
    },
    H6: {
      mobile: "24px",
      desktop: "24px",
    },
  },
  body: {
    horse: "24px",
    cat: "18px",
    mouse: "18px",
    butterfly: "12px",
  },
};

const letterSpacings = {
  heading: {
    H1: {
      mobile: "0",
      desktop: "0",
    },
    H2: {
      mobile: ".15px",
      desktop: ".25px",
    },
    H3: {
      mobile: "0",
      desktop: "0",
    },
    H4: {
      mobile: "0",
      desktop: "0",
    },
    H5: {
      mobile: ".15px",
      desktop: ".15px",
    },
    H6: {
      mobile: ".15px",
      desktop: "0",
    },
  },
  body: {
    horse: ".5px",
    cat: ".15px",
    mouse: ".5px",
    butterfly: ".25px",
  },
};

export { fontFamilies, fonts, letterSpacings, fontSizes, lineHeights };
