import getConfig from "next/config";

export class EnvironmentVariables {
  public static getNodeEnv() {
    return process.env.NODE_ENV;
  }

  public static getAppEnv() {
    return process.env.APP_ENV;
  }

  public static isWebsiteHostHttps(): boolean {
    return process.env.WEBSITE_HOST_HTTPS === `true`;
  }

  public static isWebsiteHostPrefixedWithWww(): boolean {
    return process.env.WEBSITE_PREFIX_WITH_WWW === `true`;
  }

  public static getWebsiteHost() {
    return process.env.WEBSITE_HOST;
  }

  public static getWebsiteHostPort(): number {
    return Number(process.env.WEBSITE_HOST_PORT);
  }

  public static getWebsiteInternalHost() {
    return process.env.WEBSITE_HOST_FROM_NODE;
  }

  public static getWebsiteInternalPort() {
    return Number(process.env.WEBSITE_HOST_PORT_FROM_NODE);
  }

  public static getContentfulSpace() {
    return process.env.CONTENTFUL_SPACE_ID;
  }

  public static getContentfulAccessToken() {
    return process.env.CONTENTFUL_ACCESS_TOKEN;
  }

  public static getContentfulEnvironment() {
    return process.env.CONTENTFUL_ENVIRONMENT;
  }

  public static getSalesforceOrgId() {
    return process.env.SALESFORCE_ORG_ID;
  }

  public static getGoogleReCaptchaV3SiteKey() {
    return process.env.GOOGLE_RECAPTCHA_V3_SITE_KEY;
  }

  public static getGoogleTagManagerIdAmp() {
    return process.env.GOOGLE_TAG_MANAGER_ID_AMP;
  }

  public static getGoogleTagManagerIdWeb() {
    return process.env.GOOGLE_TAG_MANAGER_ID_WEB;
  }

  public static getGooglePayEnvironment() {
    return process.env.GOOGLE_PAY_ENVIRONMENT;
  }

  public static getGoogleReCaptchaSiteIdSalesForce() {
    return process.env.GOOGLE_RECAPTCHA_V2_SITE_KEY;
  }

  public static endpointHttpsFromClient() {
    return process.env.API_ENDPOINT_HTTPS_FROM_CLIENT;
  }

  public static endpointHostFromClient() {
    return process.env.API_ENDPOINT_HOST_FROM_CLIENT;
  }

  public static endpointPortFromClient() {
    return process.env.API_ENDPOINT_PORT_FROM_CLIENT;
  }

  public static endpointVersionFromClient() {
    return process.env.API_ENDPOINT_VERSION_FROM_CLIENT;
  }

  public static endpointHttpsFromNode() {
    return process.env.API_ENDPOINT_HTTPS_FROM_NODE;
  }

  public static endpointHostFromNode() {
    return process.env.API_ENDPOINT_HOST_FROM_NODE;
  }

  public static endpointPortFromNode() {
    return process.env.API_ENDPOINT_PORT_FROM_NODE;
  }

  public static endpointVersionFromNode() {
    return process.env.API_ENDPOINT_VERSION_FROM_NODE;
  }

  public static getCacheMaxSizeInMegabytes(): number {
    return Number(process.env.CACHE_MAX_SIZE_IN_MEGABYTES);
  }

  public static getCacheTtlInSeconds(): number {
    return Number(process.env.CACHE_TTL_IN_SECONDS);
  }

  public static getAdyenEnvironment() {
    return process.env.ADYEN_ENVIRONMENT;
  }

  public static getAdyenOriginKey() {
    return process.env.ADYEN_ORIGIN_KEY;
  }

  public static getAdyenClientKey() {
    return process.env.ADYEN_CLIENT_KEY;
  }

  public static getUnleashUrl() {
    return process.env.UNLEASH_URL;
  }

  public static getUnleashAppName() {
    return process.env.UNLEASH_APP_NAME;
  }

  public static getUnleashTogglesPrefix() {
    return process.env.UNLEASH_TOGGLE_PREFIX;
  }

  public static getUnleashRefreshIntervalInSeconds(): number {
    return Number(process.env.UNLEASH_REFRESH_INTERVAL_IN_SECONDS);
  }

  public static getCommitId() {
    const { serverRuntimeConfig } = getConfig();

    return serverRuntimeConfig.COMMIT_ID;
  }

  public static getAuth0Domain() {
    return process.env.AUTH0_DOMAIN;
  }

  public static getAuth0ClientId() {
    return process.env.AUTH0_CLIENT_ID;
  }

  public static getAuth0SocialLoginTokenExpiration() {
    return parseInt(process.env.AUTH0_SOCIAL_LOGIN_TOKEN_VALIDITY);
  }

  public static getMinPasswordLength() {
    return parseInt(process.env.PASSWORD_MIN_LENGHT);
  }

  public static getLoaderTimeout() {
    const loaderTimeoutInMs = parseInt(process.env.LOADER_TIMEOUT_IN_MS);
    return !isNaN(loaderTimeoutInMs) ? loaderTimeoutInMs : 10000;
  }
}
