import { createMuiTheme } from "@material-ui/core/styles";
import { colors } from "@carfax/web-ui/core";

const MaterialUITheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.green.hex,
    },
    error: {
      main: colors.red.hex,
    },
    background: {
      default: colors.backgroundGrey.hex,
    },
  },
});

export default MaterialUITheme;
