import React from "react";
import { ReactSVG } from "react-svg";
import { styles } from "./Icon.style";

export interface IconProps {
  icon: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = React.memo(
  React.forwardRef<HTMLImageElement, IconProps>((props, ref) => {
    const { icon, className } = props;
    const svgRenderFallback = (src: string) => <img className={className} alt="" title="" src={src} />;
    return (
      <div css={styles.root()} ref={ref}>
        <ReactSVG css={styles.reactSvgIcon()} className={className} src={icon} fallback={svgRenderFallback} />
      </div>
    );
  })
);

Icon.displayName = "Icon";
